import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import SEO from "../../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="AWS / Azure Migrations" mdxType="SEO" />
    <h1 {...{
      "id": "aws--azure-migrations"
    }}>{`AWS / Azure Migrations`}</h1>
    <hr />
    <h2 {...{
      "id": "60-of-workloads-will-be-running-in-the-cloud-by-the-end-of-2020-are-you-ready"
    }}>{`60% of workloads will be running in the cloud by the end of 2020. Are you ready?`}</h2>
    <p>{`AWS and Azure are cloud services platform, that provide services in different domains such as compute, storage,
delivery and other functionality which help the business to scale and grow.`}</p>
    <p>{`Our group of experts can asses what will be the  best  cloud provider for your business use cases,
and how to make an intelligent, tactical investment in the cloud to meet your specific outcomes.`}</p>
    <br /><br />
    <h2 {...{
      "id": "azure-expressroute-to-office-365"
    }}>{`Azure ExpressRoute to Office 365`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.565310492505354%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'234\\'%3e%3cpath%20d=\\'M0%20117v117h401V0H0v117m205-25l-2%205-1%203-2%202%202%201%201%209c0%207%200%208%202%208s2-1%202-9v-8h3l4%201v7c0%207%202%2011%207%2010%202-1%202-3%200-4h-2l-1-7c0-7%200-7%203-7s2-2-1-3c-1%200-2-1-2-3%200-3-3-3-4%201%200%202-1%202-4%202s-3%200-3-3c0-2%201-3%203-4l2-2c0-2-4-1-7%201M75%2093l-1%2014c0%2012%200%2013%202%2013s2-1%202-10l1-10%204%2010%205%2010%206-10%205-10v10c0%209%200%2010%202%2010s2-1%202-14c0-12-1-13-2-13-3%200-3%200-8%2012l-5%209-4-11c-5-10-7-13-9-10m161%2012a11403%2011403%200%2000-3%2015l3-3c1-4%201-4%207-4s6%200%207%204l3%203c3%200%203%200-1-10l-5-13c-3-8-5-7-11%208m-118-3c-3%204-4%2011-1%2015s13%205%2013%201h-3c-5%200-8-3-8-7%200-6%203-8%207-8%204%201%205%200%204-2-2-2-9-1-12%201m16-1c-2%201-1%2019%200%2019%203%201%203%200%203-7s2-10%205-10l2-1c0-2-4-3-6-1h-4m13%202c-8%208%201%2022%2011%2017%205-3%207-9%205-14-3-7-11-8-16-3m21-1c-3%203-2%207%202%2010%206%203%205%208-2%205-3-1-4%201-1%203%202%201%207%201%2010-1%202-2%201-7-3-9-6-4-6-8%200-7%202%201%203%200%203-1%200-3-7-3-9%200m15%201c-6%205-2%2018%205%2018%208%200%2012-5%2011-12%200-9-9-12-16-6m75-2c-2%201%200%202%205%202s5%201-1%208c-5%208-6%209-3%2010%205%201%2013%200%2014-2%200-2-1-2-5-2h-6l6-7%205-9c0-1-14-2-15%200m17%202v8c1%208%203%2011%208%2010l6-1c3%200%204-1%203-12%200-6%200-8-2-8l-1%207c0%206%200%207-2%209-5%205-8%201-8-9l-1-7-3%203m36%200c-4%203-4%2011-1%2015s16%203%2014-1h-3c-4%202-10-1-10-5l7-1h7v-3c0-7-9-11-14-5m-161%202c-5%204-1%2013%204%2013s8-10%204-13c-2-2-6-2-8%200m36-1c-3%202-3%2010%200%2013%207%204%2013-5%208-12-1-2-5-3-8-1\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Azure ExpressRoute",
          "title": "Azure ExpressRoute",
          "src": "/static/5d88b99bd46b1af3bc7ef2bbd8b6193d/8cfe4/azure-express-route.jpg",
          "srcSet": ["/static/5d88b99bd46b1af3bc7ef2bbd8b6193d/762bd/azure-express-route.jpg 310w", "/static/5d88b99bd46b1af3bc7ef2bbd8b6193d/e83c5/azure-express-route.jpg 620w", "/static/5d88b99bd46b1af3bc7ef2bbd8b6193d/8cfe4/azure-express-route.jpg 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Azure ExpressRoute is used with Office 365. We can plan the network implementation project that will
be required if you are deploying Azure ExpressRoute for use with Office 365.`}</p>
    <p>{`Infrastructure and platform services running in Azure will often benefit by addressing network architecture and
performance considerations. We recommend ExpressRoute for Azure in these cases.`}</p>
    <p>{`Software as a Service offerings like Office 365 and Dynamics 365 have been built to be accessed securely
and reliably via the Internet.`}</p>
    <br /><br />
    <hr />
    <h2 {...{
      "id": "also"
    }}>{`Also`}</h2>
    <div className="checkmarks">
      <ul>
        <li parentName="ul">{`Determine workload requirements & TCO`}</li>
        <li parentName="ul">{`Server workload migrations (database, web, application)`}</li>
        <li parentName="ul">{`Highly scalable, high-availability compute environment`}</li>
        <li parentName="ul">{`IaaS (Lift & Shift) / PaaS`}</li>
      </ul>
    </div>
    <br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      